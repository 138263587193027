import React from "react"
import HomePageLayout from "../../components/layouts/HomePageLayout"
import SEO from "../../components/seo"
import generalClasses from "./general.module.css"

import LeftIcon from "../../images/arrow-left.inline.svg"
import RightIcon from "../../images/arrow-right.inline.svg"

import websiteImage from "../../images/case-studies/lms.jpg"
import Footer from "../../components/footer"

import { Link } from "gatsby"
import arrowLeft from "../../images/arrow-left.svg"

const Lms = props => (
  <HomePageLayout pageTitle="LMS" background>
    <SEO title="LMS Case Study" />
    <main className={generalClasses.main}>
      <Link to={"/solutions/projects"} className={generalClasses.backLink}>
        <img src={arrowLeft} alt="" className={generalClasses.backLinkImage} />
        Projects
      </Link>
      <section
        className={[generalClasses.caseStudyIntro, generalClasses.section].join(
          " "
        )}
      >
        <div>
          <h1 className={generalClasses.caseStudyHeader}>
            learning management solution
          </h1>
          <p className={generalClasses.caseStudyDescription}>
            ODIRS is a website that keeps the public informed on administrative
            process and enable users to carryout revenue task
          </p>
          <p className={generalClasses.caseStudyCategory}>
            UI / UX | WEB DEVELOPMENT
          </p>
        </div>
        <ul className={generalClasses.caseStudyDetails}>
          <li className={generalClasses.caseStudyDetail}>
            <h4 className={generalClasses.caseStudyDetailHeader}>CLIENT</h4>
            <p className={generalClasses.caseStudyDetailContent}>SME Upturn</p>
          </li>
          <li className={generalClasses.caseStudyDetail}>
            <h4 className={generalClasses.caseStudyDetailHeader}>LOCATION</h4>
            <p className={generalClasses.caseStudyDetailContent}>
              Lagos, Nigeria
            </p>
          </li>
          <li className={generalClasses.caseStudyDetail}>
            <h4 className={generalClasses.caseStudyDetailHeader}>USERS</h4>
            <p className={generalClasses.caseStudyDetailContent}>
              1000 - 1,000,000 Users
            </p>
          </li>
          <li className={generalClasses.caseStudyDetail}>
            <h4 className={generalClasses.caseStudyDetailHeader}>INDUSTRY</h4>
            <p className={generalClasses.caseStudyDetailContent}>Government</p>
          </li>
        </ul>
      </section>
      <section className={generalClasses.section}>
        <h1 className={generalClasses.meetCaseStudyHeader}>meet SME Upturn</h1>
        <p className={generalClasses.meetCaseStudyText}>
          SME Upturn Series is a community of small business owners taking
          matters into their own hands to ensure that their businesses outlives
          them! We support you to grow organically, to develop structures and
          processes that will ensure that your business outlives you.
        </p>
      </section>
      <section className={generalClasses.section}>
        <div className={generalClasses.contentMargin}>
          <h1 className={generalClasses.sectionHeader}>our idea</h1>
          <p className={generalClasses.sectionText}>
            To build a solution that helps entrepreneurs in Micro, Small and
            Medium Enterprise Scale businesses grow and get more enlightened
            about how to run their businesses successfully
          </p>
        </div>
        <div className={generalClasses.contentMargin}>
          <h1 className={generalClasses.sectionHeader}>Result</h1>
          <p className={generalClasses.sectionText}>
            We built a user-friendly application that carried out our goal of
            helping MSMEs entrepreneurs get more educated
          </p>
        </div>
      </section>
      <img
        src={websiteImage}
        className={generalClasses.displayImageFull}
        alt=""
      />
      <div className={generalClasses.caseStudyPageLinks}>
        <Link
          to="/case-studies/revenue-reporting-tool"
          className={generalClasses.caseStudyPageLink}
        >
          <span className={generalClasses.caseStudyPageLinkIcon}>
            <LeftIcon
              className={generalClasses.caseStudyPageLinkIconImage}
              width="11px"
              height="18px"
            />
          </span>
          <p className={generalClasses.caseStudyPageLinkText}>
            <span className={generalClasses.caseStudyPageLinkTag}>
              PREVIOUS
            </span>
            <span className={generalClasses.caseStudyPageLinkTitle}>
              Revenue Reporting Tool
            </span>
          </p>
        </Link>
        <Link
          to="/case-studies/tech4dev"
          className={[
            generalClasses.caseStudyPageLink,
            generalClasses.caseStudyPageLinkRight,
          ].join(" ")}
        >
          <p className={generalClasses.caseStudyPageLinkText}>
            <span className={generalClasses.caseStudyPageLinkTag}>NEXT</span>
            <span className={generalClasses.caseStudyPageLinkTitle}>
              TECH4DEV
            </span>
          </p>
          <span className={generalClasses.caseStudyPageLinkIcon}>
            <RightIcon
              className={generalClasses.caseStudyPageLinkIconImage}
              width="11px"
              height="18px"
            />
          </span>
        </Link>
      </div>
    </main>
    <Footer />
  </HomePageLayout>
)

export default Lms
